import {
  Box,
  Button,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Paper,
  Typography,
} from "@mui/material";
import moment from "moment/moment";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { CustomToolbar } from "../../components/utils/CustomFilter";
import { DataGrid, esES } from "@mui/x-data-grid";
import InfoIcon from "@mui/icons-material/Info";
import { Libro } from "./Libro";
import { Otros } from "./Otros";
import { Certificado } from "./Certificado";
import DownloadForOfflineIcon from "@mui/icons-material/DownloadForOffline";

export const HomePage = () => {
  const userAuth = useSelector((state) => state.userAuth);
  const { userInfo } = userAuth;
  const [productos, setProductos] = useState(null);

  const [viejos, setViejos] = useState(null);

  const [objeto, setObjeto] = useState({});

  const categorias = {
    "ARTICULO MEDICO": "Artículo Médico",
    "LIBRO DIGITAL": "Libro Digital",
    "CERTIFICADO DE CONGRESO": "Certificado de Congreso",
    "CERTIFICADO DE PONENCIA": "Certificado de Ponencia",
    ANTEPROYECTO: "Anteproyecto",
    CURSO: "Curso",
    "TESIS DE PREGRADO": "Tesis de Pregrado",
    "TESIS DE POSGRADO": "Tesis de Prosgrado",
    "TRABAJO DE INVESTIGACIÓN": "Trabajo de Investigación",
  };

  useEffect(() => {
    setObjeto({});
    cargar();
    cargarProductos();
  }, []);

  function cargarDoc(row) {
    const requestOptions = {
      method: "GET",
      headers: new Headers({ "X-Api-Key": process.env.REACT_APP_DCOUMENTO }),
    };
    if (row?.documentoId) {
      fetch(
        "https://portal.publimed.net/api/v1/Attachment/file/" +
          row?.documentoId,
        requestOptions
      )
        .then((response) => response.blob())
        .then(async (result) => {
          let doc = null;

          doc = URL.createObjectURL(result);
          const a = document.createElement("a");
          a.href = doc;
          a.download = row?.documentoName;
          a.click();
        })
        .catch((error) => {
          console.error(error);
        });
    }
  }

  function estadosViejos(item) {
    if (item?.tipo?.includes("LIBRO") || item?.tipo?.includes("ARTICULO")) {
      return item?.estado !== 0
        ? procesoLibro[item?.estado - 1]
        : "Procesando Pago";
    } else if (item?.tipo?.includes("CERT")) {
      return item?.estado !== 0
        ? procesoCertificado[item?.estado - 1]
        : "Procesando Pago";
    } else {
      return item?.estado !== 0
        ? procesoOtros[item?.estado - 1]
        : "Procesando Pago";
    }
  }

  function cargarProductos() {
    fetch("https://publimed.net/API/compra.php/?usuario=" + userInfo?.name, {
      method: "GET",
    })
      .then((res) => res.text())
      .then((res) => {
        let lis = JSON.parse(res);
        setViejos(
          lis?.map((val) => {
            return {
              ...val,
              viejo: true,
              productoName: val.nombre,
              categoria: categorias[val.tipo] ?? val.tipo,
              createdAt: val.fecha,
              modifiedAt: val.fecha,
            };
          }) ?? []
        );
      })
      .catch((error) => {
        console.error(error);
      });
  }

  const procesoLibro = [
    "Procesando Pago",
    "Elaboración",
    "Edición",
    "Revisión",
    "Carta de Aceptación",
    "Publicación",
  ];
  const procesoOtros = [
    "Procesando Pago",
    "Elaboración",
    "Edición",
    "Revisión",
    "Entrega",
  ];
  const procesoCertificado = [
    "Procesando Pago",
    "Elaboración",
    "Edición",
    "Entrega",
  ];

  async function cargar() {
    if (userInfo?.id) {
      const requestOptions = {
        method: "POST",
        redirect: "follow",
        body: JSON.stringify({
          id: userInfo?.id,
        }),
        headers: new Headers({ "X-Api-Key": process.env.REACT_APP_TOKEN }),
      };
      fetch("https://publimed.net/API/productos.php", requestOptions)
        .then((response) => response.text())
        .then((result) => {
          let res = JSON.parse(result);
          let lista =
            res?.list?.filter((val) => val?.estado === "COMPRADO" && val.categoria !== "CURSO") ?? [];
          setProductos([
            ...lista.map((val) => {
              return {
                ...val,
                categoria: categorias[val.categoria] ?? val.categoria,
              };
            }),
          ]);
        })
        .catch((error) => {
          console.error(error);
          setProductos([]);
        });
    }
  }

  async function cargarDetalle(row) {
    if (row?.productoId) {
      const requestOptions = {
        method: "POST",
        redirect: "follow",
        body: JSON.stringify({
          id: row?.productoId,
        }),
        headers: new Headers({ "X-Api-Key": process.env.REACT_APP_TOKEN }),
      };
      fetch("https://publimed.net/API/detalleproducto.php", requestOptions)
        .then((response) => response.text())
        .then((result) => {
          let res = JSON.parse(result);
          let prod = res ?? {};
          setObjeto({
            ...row,
            producto: prod,
          });
        })
        .catch((error) => {
          console.error(error);
          setObjeto(row);
        });
    } else {
      setObjeto(row);
    }
  }

  const columns = [
    {
      field: "Ver Progreso",
      hideable: false,
      filterable: false,
      headerClassName: "super-app-theme--header",
      headerName: "VER PROGRESO",
      width: 160,
      renderCell: (params) => (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            width: "100%",
          }}
        >
          <Button
            variant="contained"
            color="error"
            //  sx={{padding:"10px"}}
            onClick={() => {
              cargarDetalle(params.row);
            }}
          >
            <InfoIcon color="white" />
          </Button>
        </Box>
      ),
    },

    {
      field: "productoName",
      headerClassName: "super-app-theme--header",
      headerName: "Producto",
      minWidth: 200,
      flex: 1,
    },
    {
      field: "categoria",
      headerClassName: "super-app-theme--header",
      headerName: "Categoría",
      minWidth: 200,
      flex: 1,
    },
    {
      field: "proceso",
      headerClassName: "super-app-theme--header",
      headerName: "Estado",
      minWidth: 200,
      type: "singleSelect",
      valueOptions: [
        "Procesando Pago",
        "Elaboración",
        "Edición",
        "Revisión",
        "Carta de Aceptación",
        "Publicación",
        "Entrega",
      ],
      flex: 1,
      valueGetter: (params) => {
        return params.row?.viejo
          ? estadosViejos(params.row)
          : params.row?.proceso;
      },

      renderCell: (params) => (
        <>
          {params.row.viejo ? (
            <Chip
              sx={{ margin: "10px" }}
              label={estadosViejos(params.row)}
              color={
                ["Entrega", "Publicación"].includes(estadosViejos(params.row))
                  ? "success"
                  : "warning"
              }
              variant="filled"
            />
          ) : (
            <Chip
              sx={{ margin: "10px" }}
              label={params?.row?.proceso ?? ""}
              color={
                ["Entrega", "Publicación"].includes(params?.row?.proceso)
                  ? "success"
                  : "warning"
              }
              variant="filled"
            />
          )}
        </>
      ),
    },

    {
      field: "Documento",
      hideable: false,
      filterable: false,
      headerClassName: "super-app-theme--header",
      headerName: "Documento",
      width: 120,
      renderCell: (params) => (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            width: "100%",
          }}
        >
          {params.row?.documentoId && params.row?.documentoId !== "" && (
            <Button
              onClick={() => {
                cargarDoc(params.row);
              }}
              variant="contained"
              color="success"
            >
              <DownloadForOfflineIcon color="white" />
            </Button>
          )}
          {params.row?.certificado && params.row?.certificado !== "" && (
            <Button
              onClick={() => {
                const link = document.createElement("a");
                link.download = params.row?.certificado;
                link.href =
                  "https://publimed.net/archivos/" + params.row?.certificado;
                link.click();
              }}
              variant="contained"
              color="success"
            >
              <DownloadForOfflineIcon color="white" />
            </Button>
          )}
        </Box>
      ),
    },
    {
      field: "modifiedAt",
      headerClassName: "super-app-theme--header",
      headerName: "F. modificado",
      minWidth: 150,
      type: "date",
      flex: 1,
      valueGetter: (params) => {
        return new Date(
          new Date(params.row?.modifiedAt).getTime() - 5 * 60 * 60 * 1000
        );
      },
      renderCell: (params) => {
        return params.row?.modifiedAt
          ? moment(
              new Date(
                new Date(params.row?.modifiedAt).getTime() - 5 * 60 * 60 * 1000
              )
            ).format("YYYY-MM-DD HH:mm")
          : "";
      },
    },
    {
      field: "createdAt",
      headerClassName: "super-app-theme--header",
      headerName: "F. creado",
      minWidth: 150,
      type: "date",
      flex: 1,
      valueGetter: (params) => {
        return new Date(
          new Date(params.row?.createdAt).getTime() - 5 * 60 * 60 * 1000
        );
      },

      renderCell: (params) => {
        return params.row?.createdAt
          ? moment(
              new Date(
                new Date(params.row?.createdAt).getTime() - 5 * 60 * 60 * 1000
              )
            ).format("YYYY-MM-DD HH:mm")
          : "";
      },
    },
  ];

  return (
    <Box px={3} py={1}>
      <Paper sx={{ padding: "20px" }} elevation={2}>
        <Typography variant="h5">
          <strong>Mis Compras</strong>
        </Typography>

        <Box
          sx={{
            minHeight: 500,
            height: "70vh",
            "& .super-app-theme--header": {
              backgroundColor: "primary.main",
              color: "white",
              fontSize: "16px",
            },
          }}
        >
          <DataGrid
            disableColumnMenu
            hideFooterSelectedRowCount
            rows={(productos ?? []).concat(viejos ?? [])}
            loading={productos === null}
            columns={columns}
            getRowId={(row) => row.id}
            getRowHeight={() => "auto"}
            getEstimatedRowHeight={() => 200}
            localeText={esES.components.MuiDataGrid.defaultProps.localeText}
            slots={{
              toolbar: CustomToolbar,
            }}
          />
        </Box>
      </Paper>

      <Dialog
        open={objeto?.viejo !== true && objeto?.id?.length > 0}
        fullWidth
        maxWidth="md"
        onClose={() => {
          setObjeto({});
        }}
      >
        <DialogTitle></DialogTitle>
        <DialogContent>
          <Grid container spacing={2}>
            <Grid item xs={12} md={objeto?.urlVideo ? 6 : 12}>
              <Paper elevation={3} sx={{ padding: "20px" }}>
                <Typography align="center">
                  <strong> {objeto?.categoria} </strong>
                </Typography>
                <br />
                <Typography>
                  <strong> Producto: </strong> {objeto?.productoName}
                  {objeto?.especialidad?.length > 0 && (
                    <>
                      <br /> <strong>Especialidad</strong>:{" "}
                      {objeto?.especialidad}
                    </>
                  )}
                  {objeto?.producto?.especialidades?.length > 0 && (
                    <>
                      <br /> <strong>Especialidad</strong>:{" "}
                      {objeto?.producto?.especialidades?.join(", ")}
                    </>
                  )}
                  {objeto?.tema?.length > 0 && (
                    <>
                      <br /> <strong>Tema</strong>: {objeto?.tema}
                    </>
                  )}
                  {objeto?.tiempo?.length > 0 && (
                    <>
                      <br /> <strong>Tiempo</strong>: {objeto?.tiempo}
                    </>
                  )}
                  {objeto?.nombreEleccion?.length > 0 && (
                    <>
                      <br /> <strong>Elección</strong>: {objeto?.nombreEleccion}
                    </>
                  )}
                </Typography>
                <br />
                <Box
                  alignItems={"right"}
                  textAlign={"center"}
                  justifyContent={"center"}
                >
                  {objeto?.categoria &&
                    (objeto?.categoria?.includes("LIBRO") ||
                      objeto?.categoria?.includes("ARTICULO")) && (
                      <Libro
                        estado={procesoLibro.indexOf(objeto?.proceso) + 1}
                      />
                    )}
                  {objeto?.categoria &&
                    objeto?.categoria?.includes("CERTIFICADO") && (
                      <Certificado
                        estado={procesoCertificado.indexOf(objeto?.proceso) + 1}
                      />
                    )}
                  {objeto?.categoria &&
                    !objeto?.categoria?.includes("CERTIFICADO") &&
                    !objeto?.categoria?.includes("LIBRO") &&
                    !objeto?.categoria?.includes("ARTICULO") && (
                      <Otros
                        estado={procesoOtros.indexOf(objeto?.proceso) + 1}
                      />
                    )}
                  {objeto?.documentoId && objeto?.documentoId !== "" && (
                    <Button
                      onClick={() => {
                        cargarDoc(objeto);
                      }}
                      variant="contained"
                      color="primary"
                    >
                      Descargar Certificado
                    </Button>
                  )}
                </Box>
              </Paper>
            </Grid>
            {objeto?.urlVideo && (
              <Grid
                item
                xs={12}
                md={6}
                sx={{ display: "flex", flexWrap: "wrap", alignItems: "center" }}
              >
                <Typography sx={{ width: "100%" }}>
                  <strong>Video: </strong>
                  <a href={objeto?.urlVideo} target="_blank">
                    Click aquí para el enlace
                  </a>
                </Typography>
                <iframe
                  title="video"
                  src="https://drive.google.com/file/d/187JNl2q5Ut4yVW3kqAppOyB3-rYslEsm/preview"
                  width="640"
                  height="480"
                  allow="autoplay"
                  allowFullScreen
                ></iframe>
              </Grid>
            )}
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button
            color="error"
            variant="contained"
            onClick={() => {
              setObjeto({});
            }}
          >
            CERRAR
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={objeto?.viejo === true && objeto?.id > 0}
        fullWidth
        maxWidth="md"
        onClose={() => {
          setObjeto({});
        }}
      >
        <DialogTitle></DialogTitle>
        <DialogContent>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Paper elevation={3} sx={{ padding: "20px" }}>
                <Typography align="center">
                  <strong> {objeto?.categoria} </strong>
                </Typography>
                <br />
                <Typography>
                  <strong> Producto: </strong> {objeto?.productoName}
                  {objeto?.especialidad?.length > 0 && (
                    <>
                      <br /> <strong>Especialidad</strong>:{" "}
                      {objeto?.especialidad}
                    </>
                  )}
                  {objeto?.tema?.length > 0 && (
                    <>
                      <br /> <strong>Tema</strong>: {objeto?.tema}
                    </>
                  )}
                  {objeto?.tiempo?.length > 0 && (
                    <>
                      <br /> <strong>Tiempo</strong>: {objeto?.tiempo}
                    </>
                  )}
                </Typography>
                <br />
                <Box alignItems={"right"}>
                  {objeto?.categoria &&
                    (objeto?.categoria?.includes("LIBRO") ||
                      objeto?.categoria?.includes("ARTICULO")) && (
                      <Libro estado={objeto?.estado} />
                    )}
                  {objeto?.categoria &&
                    objeto?.categoria?.includes("CERTIFICADO") && (
                      <Certificado estado={objeto?.estado} />
                    )}
                  {objeto?.categoria &&
                    !objeto?.categoria?.includes("CERTIFICADO") &&
                    !objeto?.categoria?.includes("LIBRO") &&
                    !objeto?.categoria?.includes("ARTICULO") && (
                      <Otros estado={objeto?.estado} />
                    )}
                  {objeto?.certificado && objeto?.certificado !== "" && (
                    <Button
                      onClick={() => {
                        const link = document.createElement("a");
                        link.download = objeto?.certificado;
                        link.href =
                          "https://publimed.net/archivos/" +
                          objeto?.certificado;
                        link.click();
                      }}
                      variant="contained"
                      color="primary"
                    >
                      Descargar Certificado
                    </Button>
                  )}
                </Box>
              </Paper>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button
            color="error"
            variant="contained"
            onClick={() => {
              setObjeto({});
            }}
          >
            CERRAR
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};
