import { Typography } from "@mui/material";
import { Container } from "@mui/system";
import React from "react";
export default function Unauthorized() {
  return (
    <Container>
      <Typography variant="h6">No autorizado</Typography>
    </Container>
  );
}
