const initialState = {
    isLoggedIn: false,
    userInfo: {
    }
}

export const authReducer = (state = initialState, action) => {
    switch (action.type) {
        case 'AUTH_FAIL':
            return { loading: false, error: action.payload }
        case 'AUTH_ADD_ITEMS':
            return { ...state, userInfo: { ...action.payload, ...state.userInfo } };
        case 'AUTH_LOGOUT':
            return { userInfo: {} }
        default:
            return state
    }
}