import { CircularProgress, Grid, Backdrop } from "@mui/material";
import React from "react";
import PropTypes from "prop-types";

export const Cargando = ({ open = false }) => {
  return (
    <Backdrop
      sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 10000 }}
      open={open}
    >
      <CircularProgress color="inherit" />
    </Backdrop>
  );
};

export const CargandoPage = () => {
  return (
    <Grid
      container
      spacing={0}
      direction="column"
      alignItems="center"
      justifyContent="center"
      style={{ minHeight: "100vh" }}
    >
      <Grid item xs={3}>
        <CircularProgress />
      </Grid>
    </Grid>
  );
};

Cargando.propTypes = {
  open: PropTypes.bool.isRequired,
};
