import { Box } from "@mui/material";
import { GridToolbarColumnsButton, GridToolbarFilterButton, GridToolbarQuickFilter } from "@mui/x-data-grid";

export function CustomToolbar() {
  return (
    <Box
      sx={{
        p: 0.5,
        pb: 0,
      }}
    >
      <GridToolbarQuickFilter
        quickFilterParser={(searchInput) =>{
          return searchInput
            .split(",")
            .map((value) => value.trim())
            .filter((value, column) => value !== "" )
        }
        }
      />
      <GridToolbarColumnsButton />
      <GridToolbarFilterButton />
    </Box>
  );
}