import * as React from "react";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { Paper } from "@mui/material";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import { useState, useEffect } from "react";
import logo from "../../assets/logoFondo.png";
import { useDispatch, useSelector } from "react-redux";
import { Cargando } from "../../components/utils/Cargando";
import { respuesta } from "../../components/utils/Respuesta";

export const LoginPage = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  // const numero = Math.floor(Math.random() * (6 - 1) + 1);
  // const imag = "https://publimed.net/" + numero + ".jpg";

  const [imag, setImag] = useState(
    "https://publimed.net/" + Math.floor(Math.random() * (6 - 1) + 1) + ".jpg"
  );

  const userAuth = useSelector((state) => state.userAuth?.userInfo);
  useEffect(() => {
    if (userAuth?.id) {
      navigate("/");
    }
  }, [userAuth, navigate]);

  const [load, setLoad] = useState(false);
  const [olvidar, setOlvidar] = useState(false);
  const defaultTheme = createTheme();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const regex =
    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

  function validarCorreoElectronico(correoElectronico) {
    return !!regex.test(correoElectronico.toLowerCase());
  }

  function enviarCorreo() {
    setLoad(true);

    const requestOptions = {
      method: "POST",
      redirect: "follow",
      body: JSON.stringify({
        correo: email,
      }),
      headers: new Headers({ "X-Api-Key": process.env.REACT_APP_TOKEN }),
    };
    fetch("https://publimed.net/API/recordar.php", requestOptions)
      .then(async (response) => {
        return await respuesta(response);
      })
      .then((result) => {
        setLoad(false);
        let data = JSON.parse(result);
        if (data?.mensaje) {
          Swal.fire({
            icon: "success",
            text: "Se enviará un correo con sus credenciales si existe el usuario",
          });
        }else{
          Swal.fire({
            icon: "error",
            text: data?.error ?? "Error al enviar correo",
          });
        }
        
      })
      .catch((error) => {
        console.error(error);
        setLoad(false);
        Swal.fire({
          icon: "error",
          text: "Error al enviar correo",
        });
      });

    setLoad(false);
  }

  const handleSubmit = async (e) => {
    setLoad(true);
    const requestOptions = {
      method: "POST",
      redirect: "follow",
      body: JSON.stringify({
        correo: email,
        contraseña: password,
      }),
      headers: new Headers({ "X-Api-Key": process.env.REACT_APP_TOKEN }),
    };

    fetch("https://publimed.net/API/login.php", requestOptions)
      .then(async (response) => {
        return await respuesta(response);
      })
      .then((result) => {
        setLoad(false);
        let data = JSON.parse(result);
        let informacion = data?.list[0];
        if (informacion) {
          dispatch({
            type: "AUTH_ADD_ITEMS",
            payload: {
              ...informacion,
            },
          });
          localStorage.setItem(
            "credPublimed",
            btoa(
              JSON.stringify({
                email: email,
                password: password,
              })
            )
          );
        } else {
          Swal.fire({
            icon: "error",
            text: "Las credenciales no pertenecen a ningún usuario",
          });
          dispatch({
            type: "AUTH_FAIL",
            payload: "Cliente no encontrado",
          });
          localStorage.removeItem("credPublimed");
        }
      })
      .catch((error) => {
        console.error(error);
        setLoad(false);
        localStorage.removeItem("credPublimed");
        Swal.fire({
          icon: "error",
          text: "Las credenciales no pertenecen a ningún usuario",
        });
      });
  };

  return (
    <div>
      <ThemeProvider theme={defaultTheme}>
        <Grid container component="main" sx={{ height: "100vh" }}>
          <Grid
            item
            xs={false}
            sm={6}
            md={8}
            sx={{
              backgroundImage: "url(" + imag + ")",
              backgroundRepeat: "no-repeat",
              backgroundColor: (t) =>
                t.palette.mode === "light"
                  ? t.palette.grey[50]
                  : t.palette.grey[900],
              backgroundSize: "cover",
              backgroundPosition: "center",
            }}
          />
          <Grid
            item
            xs={12}
            sm={6}
            md={4}
            component={Paper}
            elevation={6}
            square
          >
            <Box
              px={2}
              pt={3}
              display={"flex"}
              justifyContent={"center"}
              alignItems={"center"}
            >
              <img src={logo} alt="logo" width={300} />
            </Box>
            <Box
              sx={{
                my: 5,
                mx: 4,
                display: "flex",
                flexDirection: "column",
              }}
            >
              {olvidar ? (
                <>
                  <Typography variant="h4" sx={{ textAlign: "center" }}>
                    <strong>Recordar Contraseña</strong>
                  </Typography>
                  <Typography variant="h6" pt={1} sx={{ textAlign: "left" }}>
                    Ingrese el correo de su cuenta, se enviará un
                    correo con sus credenciales en caso de existir.
                  </Typography>
                  <form>
                    <Box sx={{ mt: 5 }}>
                      <Typography>
                        <strong>Correo</strong>
                      </Typography>
                      <TextField
                        fullWidth
                        size="small"
                        id="publimed-user"
                        name="publimed-user"
                        autoComplete="publimed-user2"
                        value={email}
                        onChange={(e) => setEmail(e.target.value?.trim())}
                        autoFocus
                        onKeyDown={(e) => {
                          if (
                            e.key === "Enter" &&
                            email.trim().length !== 0 &&
                            password.trim().length !== 0
                          ) {
                            handleSubmit();
                          }
                        }}
                      />
                      <Button
                        fullWidth
                        disabled={
                          email.trim().length === 0 ||
                          !validarCorreoElectronico(email?.trim() ?? "")
                        }
                        onClick={() => enviarCorreo()}
                        variant="contained"
                        color="warning"
                        sx={{ mt: 5, mb: 2 }}
                      >
                        Enviar Correo
                      </Button>
                      <Button
                        fullWidth
                        onClick={() => setOlvidar(false)}
                        variant="outlined"
                        color="error"
                        sx={{ mt: 5, mb: 2 }}
                      >
                        Regresar
                      </Button>
                      <Typography
                        variant="body2"
                        color="text.secondary"
                        align="center"
                        sx={{ mt: 5 }}
                      >
                        {"Copyright © "}
                        <a
                          color="inherit"
                          href="https://publimed.net"
                          target="_blank"
                          rel="noreferrer"
                        >
                          Publimed
                        </a>
                        {" " + new Date().getFullYear()}
                        {"."}
                      </Typography>
                    </Box>
                  </form>
                </>
              ) : (
                <>
                  <Typography variant="h4" sx={{ textAlign: "center" }}>
                    <strong>Iniciar Sesión</strong>
                  </Typography>

                  <form>
                    <Box sx={{ mt: 5 }}>
                      <Typography>
                        <strong>Correo </strong>
                      </Typography>
                      <TextField
                        fullWidth
                        size="small"
                        id="publimed-user"
                        name="publimed-user"
                        autoComplete="publimed-user2"
                        value={email}
                        onChange={(e) => setEmail(e.target.value?.trim())}
                        autoFocus
                        onKeyDown={(e) => {
                          if (
                            e.key === "Enter" &&
                            email.trim().length !== 0 &&
                            password.trim().length !== 0
                          ) {
                            handleSubmit();
                          }
                        }}
                      />
                      <Typography sx={{ mt: 3 }}>
                        <strong>Contraseña</strong>
                      </Typography>
                      <TextField
                        size="small"
                        fullWidth
                        type="password"
                        id="publimed-contraseña"
                        name="publimed-contraseña"
                        autoComplete="publimed-contraseña"
                        value={password}
                        onChange={(e) => setPassword(e.target.value?.trim())}
                        onKeyDown={(e) => {
                          if (
                            e.key === "Enter" &&
                            email.trim().length !== 0 &&
                            password.trim().length !== 0
                          ) {
                            handleSubmit();
                          }
                        }}
                      />

                      <Button
                        fullWidth
                        disabled={
                          email.trim().length === 0 ||
                          password.trim().length === 0 ||
                          !validarCorreoElectronico(email?.trim() ?? "")
                        }
                        onClick={() => handleSubmit()}
                        variant="contained"
                        sx={{ mt: 5, mb: 2 }}
                      >
                        Ingresar
                      </Button>

                      <Button
                        fullWidth
                        onClick={() => setOlvidar(true)}
                        color="error"
                        variant="outlined"
                        sx={{ mt: 5, mb: 2 }}
                      >
                        ¿Olvidaste tu contraseña?
                      </Button>
                      <Typography
                        variant="body2"
                        color="text.secondary"
                        align="center"
                        sx={{ mt: 5 }}
                      >
                        {"Copyright © "}
                        <a
                          color="inherit"
                          href="https://publimed.net"
                          target="_blank"
                          rel="noreferrer"
                        >
                          Publimed
                        </a>
                        {" " + new Date().getFullYear()}
                        {"."}
                      </Typography>
                    </Box>
                  </form>
                </>
              )}
            </Box>
          </Grid>

          <Cargando open={load} />
        </Grid>
      </ThemeProvider>
    </div>
  );
};
