import './index.css';
import { AppRouters } from './routers/AppRoutes';


function App() {
  return (
    <AppRouters />
  );
}
export default App;
