import { AppBar, Box, Toolbar, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import React from "react";
import { MenuAccount } from "./MenuAccount";
import logo from "../../assets/letras.png";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";

export const Nav = (props) => {
  const navigate = useNavigate();
  const userAuth = useSelector((state) => state.userAuth);
  const { userInfo } = userAuth;

  return (
    <>
      {props.isAuth && (
        <>
          <Box pb={5} sx={{ flexGrow: 1 }}>
            <AppBar pb={5} variant="outlined" position="absolute" elevation={0}>
              <Toolbar variant="dense">
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                  onClick={() => navigate("")}
                >
                  <img src={logo} alt="logo" width={200} />
                </Box>
                <Typography sx={{ flexGrow: 1 }}></Typography>
                <Box
                  sx={{
                    display: { xs: "none", sm: "flex" },
                    flexDirection: "row",
                    alignItems: "center",
                    gap: 1.5,
                  }}
                >
                  <Typography fontWeight={"bold"}>
                    {userInfo?.nombre}
                  </Typography>
                  <MenuAccount />
                </Box>
              </Toolbar>
            </AppBar>
          </Box>
          <Box pt={2}></Box>
        </>
      )}
      {props.children}
    </>
  );
};

Nav.propTypes = {
  children: PropTypes.any.isRequired,
  isAuth: PropTypes.bool.isRequired,
};
