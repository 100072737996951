import React, { useState, useEffect } from "react";
import { Routes, Route, BrowserRouter, Navigate } from "react-router-dom";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import { PublicRoute, PublicRoute2 } from "./PublicRoute";
import { useDispatch, useSelector } from "react-redux";
import CssBaseline from "@mui/material/CssBaseline";
import { CargandoPage } from "../components/utils/Cargando";
import { LoginPage } from "../pages/Login/LoginPage";
import { HomePage } from "../pages/Home/HomePage";
import { Nav } from "../components/Navigation/Nav";
import Unauthorized from "../components/Unauthorized";

import { logout } from "../redux/actions/AuthAction";
import { respuesta } from "../components/utils/Respuesta";
export const AppRouters = () => {
  const [checking, setChecking] = useState(true);
  const [isAuth, setIsAuth] = useState(false);
  const dispatch = useDispatch();
  const mode = "light";

  const userAuth = useSelector((state) => state.userAuth);
  const { userInfo } = userAuth;

  useEffect(() => {
    cargar();
  }, []);

  useEffect(() => {
    if (userInfo?.id) {
      setChecking(false);
      setIsAuth(true);
    } else {
      setIsAuth(false);
    }
  }, [userInfo]);

  function cargar() {
    setChecking(true);
    let inf = localStorage.getItem("credPublimed");
    let datos = null;
    try {
      datos = inf ? JSON.parse(atob(inf)) : null;
    } catch (error) {
      localStorage.removeItem("credPublimed");
    }
    if (datos?.email && datos?.password) {
      const requestOptions = {
        method: "POST",
        redirect: "follow",
        body: JSON.stringify({
          correo: datos?.email,
          contraseña: datos?.password,
        }),
        headers: new Headers({ "X-Api-Key": process.env.REACT_APP_TOKEN }),
      };

      fetch("https://publimed.net/API/login.php", requestOptions)
        .then(async (response) => {
          return await respuesta(response);
        })
        .then(async (result) => {
          let res = JSON.parse(result);
          let informacion = res?.list[0];

          if (informacion) {
            localStorage.setItem(
              "credPublimed",
              btoa(
                JSON.stringify({
                  email: datos.email,
                  password: datos.password,
                })
              )
            );
            dispatch({
              type: "AUTH_ADD_ITEMS",
              payload: {
                ...informacion,
              },
            });
          } else {
            dispatch({
              type: "AUTH_FAIL",
              payload: "Cliente no encontrado",
            });
            dispatch(logout());
            setChecking(false);
          }
        })
        .catch((error) => {
          dispatch({
            type: "AUTH_FAIL",
            payload: "Cliente no encontrado",
          });
          dispatch(logout());
          console.error(error);
        });
    } else {
      dispatch({
        type: "AUTH_FAIL",
        payload: "Cliente no encontrado",
      });
      dispatch(logout());
      setChecking(false);
    }
  }

  const theme = React.useMemo(
    () =>
      createTheme({
        palette: {
          background: {
            default: "#f0f2f5",
          },
          primary: {
            main: "#489dc7",
          },
          secondary: {
            main: "#62ab68",
          },
          mode,
        },
      }),
    [mode]
  );

  if (checking) {
    return <CargandoPage />;
  }

  return (
    <BrowserRouter>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <Nav isAuth={isAuth} info={userInfo}>
          <Routes>
            <Route element={<PublicRoute />}>
              <Route path="/" element={<HomePage />} />
            </Route>
            <Route element={<PublicRoute2 />}>
              <Route path="/login" element={<LoginPage />} />
            </Route>

            <Route path="/unauthorized" element={<Unauthorized />} />
            <Route
              path="/*"
              element={<Navigate to={isAuth ? "/" : "/login"} />}
            />
          </Routes>
        </Nav>
      </ThemeProvider>
    </BrowserRouter>
  );
};
