import React, { useState } from "react";
import {
  Box,
  Menu,
  MenuItem,
  IconButton,
  Avatar,
  ListItemIcon,
  Typography,
  Dialog,
  DialogTitle,
  Grid,
  DialogContent,
  Button,
  DialogActions,
  TextField,
  Stack,
  Backdrop,
} from "@mui/material";
import ExitToAppIcon from "@mui/icons-material/ExitToApp";
import { useDispatch, useSelector } from "react-redux";
import { logout } from "../../redux/actions/AuthAction";
import Swal from "sweetalert2";
import VpnKeyIcon from "@mui/icons-material/VpnKey";
import { Cargando } from "../utils/Cargando";
import { respuesta } from "../utils/Respuesta";

export const MenuAccount = () => {
  const userAuth = useSelector((state) => state.userAuth);
  const { userInfo } = userAuth;
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const dispatch = useDispatch();

  const [ventana, setVentana] = useState(false);
  const [objeto, setObjeto] = useState({});
  const [load, setLoad] = useState(false);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const logoutHandler = () => {
    dispatch(logout());
  };

  function limpiar() {
    setVentana(false);
    setObjeto({});
  }

  function actualizar() {
    setLoad(true);
    setVentana(false);

    const requestOptions = {
      method: "POST",
      redirect: "follow",
      body: JSON.stringify({
        nueva: objeto?.contra,
        correo: userInfo?.name,
        contraseña: objeto?.actual,
      }),
      headers: new Headers({ "X-Api-Key": process.env.REACT_APP_TOKEN }),
    };
    fetch("https://publimed.net/API/reset.php", requestOptions)
      .then(async (response) => {
        return await respuesta(response);
      })
      .then((result) => {
        setLoad(false);
        let data = JSON.parse(result);
        if (data?.id) {
          limpiar();
          dispatch(logout());
          Swal.fire({
            icon: "success",
            text: "Credenciales actualizadas, ingrese nuevamente",
          });
        } else {
          setLoad(false);
          Swal.fire({
            icon: "error",
            text: "Error al actualizar credenciales",
          });
        }
      })
      .catch((error) => {
        console.error(error);
        setLoad(false);
        Swal.fire({
          icon: "error",
          text: "Error al actualizar credenciales",
        });
      });
  }

  return (
    <>
      <IconButton onClick={handleClick}>
        <Stack
          direction={"row"}
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
          spacing={1}
        >
          <Avatar />
        </Stack>
      </IconButton>
      <Menu
        id="basic-menu"
        open={open}
        onClose={handleClose}
        onClick={handleClose}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        sx={{ marginTop: "10px" }}
        elevation={1}
      >
        <MenuItem>
          <ListItemIcon>
            <Avatar />
          </ListItemIcon>
          <Box pl={2}>
            {userInfo && (
              <>
                <Typography style={{ fontWeight: "bold" }}>
                  {userInfo?.nombre}
                </Typography>
                <Typography
                  style={{
                    width: 200,
                    textOverflow: "ellipsis",
                    overflow: "hidden",
                  }}
                >
                  {userInfo?.name}
                </Typography>
              </>
            )}
          </Box>
        </MenuItem>
        <MenuItem onClick={() => setVentana(true)}>
          <ListItemIcon>
            <VpnKeyIcon />
          </ListItemIcon>
          Cambiar Contraseña
        </MenuItem>
        <MenuItem onClick={logoutHandler}>
          <ListItemIcon>
            <ExitToAppIcon />
          </ListItemIcon>
          Salir
        </MenuItem>
      </Menu>
      <Backdrop open={userInfo?.nuevo === "SI"}></Backdrop>

      <Dialog
        open={ventana || userInfo?.nuevo === "SI"}
        fullWidth
        maxWidth="sm"
        onClose={() => {
          if (userInfo?.nuevo !== "SI") {
            limpiar();
          }
        }}
      >
        <DialogTitle>
          <Grid container>
            <Grid item xs={12}>
              <Typography sx={{ fontSize: "24px" }}>
                <strong>Cambio de Contraseña</strong>
              </Typography>
            </Grid>
          </Grid>
        </DialogTitle>
        <DialogContent>
          <Box px={1}>
            <Typography>
              <strong>Contraseña Actual *</strong>
            </Typography>
            <TextField
              fullWidth
              size="small"
              value={objeto?.actual ?? ""}
              autoComplete="off"
              type="password"
              onChange={(e) => setObjeto({ ...objeto, actual: e.target.value })}
              error={objeto?.actual?.length < 1}
            />
          </Box>
          <Box px={1}>
            <Typography>
              <strong>Nueva Contraseña *</strong> (Min: 6 caracteres)
            </Typography>
            <TextField
              fullWidth
              size="small"
              value={objeto?.contra ?? ""}
              autoComplete="off"
              type="password"
              onChange={(e) => setObjeto({ ...objeto, contra: e.target.value })}
              error={objeto?.contra?.length < 6}
            />
          </Box>
          <Box px={1}>
            <Typography>
              <strong>Confirmar Nueva Contraseña *</strong>
            </Typography>
            <TextField
              fullWidth
              autoComplete="off"
              size="small"
              type="password"
              value={objeto?.contra2 ?? ""}
              onChange={(e) =>
                setObjeto({ ...objeto, contra2: e.target.value })
              }
              error={
                objeto?.contra2?.length < 6 ||
                objeto?.contra2 !== objeto?.contra
              }
            />
          </Box>
        </DialogContent>
        <DialogActions>
          {userInfo?.nuevo !== "SI" && (
            <Button
              color="error"
              variant="contained"
              onClick={() => {
                limpiar();
              }}
            >
              CANCELAR
            </Button>
          )}
          <Button
            disabled={
              objeto?.contra2?.length < 6 ||
              objeto?.contra?.length < 6 ||
              typeof objeto?.actual !== "string" ||
              objeto?.actual?.length < 1 ||
              typeof objeto?.contra2 !== "string" ||
              typeof objeto?.contra !== "string" ||
              objeto?.contra2 !== objeto?.contra
            }
            color="success"
            variant="contained"
            onClick={() => actualizar()}
          >
            CONFIRMAR
          </Button>
        </DialogActions>
      </Dialog>

      <Cargando open={load} />
    </>
  );
};
